import { Layout } from '@hp/layout';
import { Homepage } from '@hp/main';
import { Navigation } from '@hp/navigation';
import { QueryParams } from '@hp/seo';
import { NextPage, NextPageContext } from 'next';
import React from 'react';

type Props = {
  id?: string;
  error?: string;
  payment?: string;
};

const Index: NextPage<Props> = ({ id, error, payment }) => {
  return (
    <Layout metaRoute="default">
      <Navigation onlyLangSwitch />

      <Homepage id={id} paymentError={error} paymentRedirected={payment} />
    </Layout>
  );
};

Index.getInitialProps = async ({ query }: NextPageContext) => {
  return ({
    id: query[QueryParams.id],
    payment: query[QueryParams.payment],
    error: query[QueryParams.error],
  } as unknown) as Props;
};

export default Index;
